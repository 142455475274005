import React from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faCartPlus } from "@fortawesome/pro-solid-svg-icons";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
class ResourcesListItem extends React.Component {
    render() {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        let resource = this.props.resource;
        let hasUrl = resource.url ? true : false;
        let fileUrl = hasUrl
            ? resource.url
            : entryPoint +
              "/resource/serve-file/" +
              resource.id +
              "/" +
              resource.file.filePath;

        const { user } = this.props;

        // Extract and process access control groups and users
        const accessControlUserGroups =
            resource.userGroupsCustom?.map((group) => group.id) || [];
        const accessControlUsers =
            resource.usersCustom?.map((user) => user.id) || [];
        let resourceLocked = false;

        // Perform access checks if access control lists are defined
        if (
            accessControlUserGroups.length > 0 ||
            accessControlUsers.length > 0
        ) {
            // Check if the current user is directly allowed
            const isUserAllowed = accessControlUsers.includes(user.id);

            // Extract current user group IDs and check for intersection with allowed groups
            const userGroupIds =
                user.accessControlUserGroups?.map((group) => group.id) || [];
            const isGroupAllowed = accessControlUserGroups.some((groupId) =>
                userGroupIds.includes(groupId)
            );

            // Lock the resource if neither the user nor their groups are allowed
            resourceLocked = !(isUserAllowed || isGroupAllowed);
            if (resourceLocked) {
                fileUrl = "#";
            }
        }

        if (!resource.file && !resource.url) {
            return null;
        }

        let coverImage = false;

        if (resource.coverImage && resource.coverImage.filePath) {
            coverImage = entryPoint + "/media/" + resource.coverImage.filePath;
        }

        const DynamicElement = fileUrl !== "#" ? "a" : "div";
        const props =
            fileUrl !== "#"
                ? { href: fileUrl, target: "_blank" }
                : {
                      onClick: () =>
                          alert(
                              "You do not have permission to view this resource."
                          ),
                  };

        return (
            <Col key={`resource-${resource.id}`} xl="4" md="6" xs="12">
                {resourceLocked && (
                    <div className="resource-item__overlay">
                        <FontAwesomeIcon
                            icon={faLock}
                            className="overlay-lock-icon"
                        />
                    </div>
                )}
                <div className="filter-page-resource-item">
                    <div
                        className={
                            "resource-item__image" +
                            (coverImage ? " has-image" : "")
                        }
                    >
                        <DynamicElement {...props}>
                            {coverImage !== false && (
                                <>
                                    <img src={coverImage} />
                                </>
                            )}

                            {coverImage === false && (
                                <div className="resource-item__no-image">
                                    <FontAwesomeIcon
                                        icon={
                                            hasUrl
                                                ? faGlobe
                                                : faCloudDownloadAlt
                                        }
                                    />
                                </div>
                            )}
                        </DynamicElement>
                    </div>
                    <div className="resource-item__details">
                        <div className="resource-item__text">
                            <div className="resource-item__list-icon">
                                <FontAwesomeIcon
                                    icon={hasUrl ? faGlobe : faFile}
                                />
                            </div>
                            <div className="resource-item__title">
                                <DynamicElement {...props}>
                                    {resource.title}
                                </DynamicElement>
                            </div>
                            <div className="resource-item__category">
                                {resource.resourceCategoryTitle
                                    ? resource.resourceCategoryTitle
                                    : ""}
                            </div>
                        </div>
                        <div className="resource-item__buttons">
                            {this.props.user && (
                                <div
                                    className={
                                        "resource-item__button button-fav" +
                                        (this.props.isFavourite === true
                                            ? " is-fav"
                                            : "")
                                    }
                                    onClick={() =>
                                        this.props.onFavourite(resource.id)
                                    }
                                >
                                    <FontAwesomeIcon icon={faStar} />
                                </div>
                            )}

                            {hasUrl === true && !resourceLocked && (
                                <div className="resource-item__button">
                                    <a href={resource.url} target="_blank">
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </a>
                                </div>
                            )}

                            {hasUrl === false && !resourceLocked && (
                                <>
                                    <div className="resource-item__button">
                                        <DynamicElement {...props}>
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                            />
                                        </DynamicElement>
                                    </div>

                                    <div
                                        className="resource-item__button"
                                        onClick={() =>
                                            this.props.onAddToBasket(resource)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faCartPlus} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {resourceLocked && (
                        <div className="resource-item__locked">
                            <div className="resource-item__locked-inner">
                                <div className="resource-item__locked-icon">
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        color="white"
                                    />
                                </div>
                                <div className="resource-item__locked-text">
                                    Access denied
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        );
    }
}

export default ResourcesListItem;

