import React from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

class ResourcesFile extends React.Component {
    render() {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        const { user, resource } = this.props;
        if (!this.props.resource.file && !this.props.resource.url) {
            return null;
        }

        let hasUrl = this.props.resource.url ? true : false;
        let fileUrl = hasUrl
            ? this.props.resource.url
            : entryPoint +
              "/resource/serve-file/" +
              this.props.resource.id +
              "/" +
              this.props.resource.file.filePath;

        let accessControlUserGroups = resource.userGroupsCustom;
        let accessControlUsers = resource.usersCustom;
        let resourceLocked = false;
        if (
            (accessControlUserGroups && accessControlUserGroups.length > 0) ||
            (accessControlUsers && accessControlUsers.length > 0)
        ) {
            if (
                !accessControlUsers.includes(user.id) &&
                !accessControlUserGroups.includes(user.accessControlUserGroups)
            ) {
                resourceLocked = true;
                fileUrl = "#";
            }
        }
        const DynamicElement = fileUrl !== "#" ? "a" : "div";
        const props =
            fileUrl !== "#"
                ? {
                      href: fileUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                  }
                : {
                      onClick: () =>
                          alert(
                              "You do not have permission to view this resource."
                          ),
                  };
        return (
            <Col
                key={`resource-${this.props.index}`}
                className="p-2 resource resource-fav-container"
                xl="12"
                xs="12"
            >
                <div className="p-3 mb-1">
                    <DynamicElement {...props} className="stretched-link">
                        <FontAwesomeIcon
                            icon={hasUrl ? faGlobe : faFile}
                            className="mr-2"
                        />{" "}
                        {this.props.resource.title}
                        {this.props.resource.resourceCategory && (
                            <div className="resource-category">
                                <div>
                                    {this.props.resource.resourceCategory.title}
                                </div>
                            </div>
                        )}
                    </DynamicElement>
                    {this.props.user && (
                        <div
                            className={
                                "resource__user-favourite" +
                                (this.props.isFavourite === true ? " fav" : "")
                            }
                            onClick={() =>
                                this.props.onFavourite(this.props.resource.id)
                            }
                        >
                            <FontAwesomeIcon icon={faStar} className="mr-2" />
                        </div>
                    )}

                    {resourceLocked && (
                        <div className="fav-resource-item__locked mt-1">
                            <div className="resource-item__locked-inner">
                                <div className="resource-item__locked-icon">
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        color="white"
                                    />
                                </div>
                                <div className="resource-item__locked-text">
                                    Access denied
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        );
    }
}

export default ResourcesFile;

